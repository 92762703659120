import React from "react";
import "../styles/App.css";
import "../styles/Landing.css";
import NavBar from "../components/NavBar";
import { Button } from "../components/Button";
import Footer from "../components/Footer";
import ServiceCards from "../components/ServiceCards";

export default function Services() {
  return (
    <>
      <NavBar
        title="Moving Services | Point Blank Moving"
        description="Point Blank Moving company services page. Kansas City's premier moving company! Learn about what services we have to offer."
      />
      <div className="service-container">
        <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>Services</h1>
        <div className="landing-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            path="/Quote"
          >
            Check Availability
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            path="/Contact"
          >
            GET IN CONTACT
          </Button>
        </div>
      </div>
      <ServiceCards />
      <Footer />
    </>
  );
}
