import React from "react";
import "../styles/LandingCards.css";
import CardItem from "./CardItem";
import "../styles/Landing.css";
import { StaticImage } from "gatsby-plugin-image";

function ServiceCards() {
  return (
    <div className="cards">
      <h1>Find out more about the services we offer!</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              text="Local Moving Service"
              label="Local Moving"
              path="/LocalMoving"
            >
              <StaticImage
                className="cards__item__img"
                alt="Local Moving Service"
                src="../images/elevator-2.jpg"
                placeholder="blurred"
                loading="eager"
                layout="constrained"
                fadeIn={false}
              />
            </CardItem>
            <CardItem
              text="Long Distance Moving"
              label="Long Distance"
              path="/LongDistanceMoving"
            >
              <StaticImage
                className="cards__item__img"
                alt="Long Distance Moving"
                src="../images/moving-bed.jpg"
                placeholder="blurred"
                loading="eager"
                layout="constrained"
                fadeIn={false}
              />
            </CardItem>
            <CardItem
              text="Heavy Lifting Moving"
              label="Heavy Lifting"
              path="/HeavyLiftingMoving"
            >
              <StaticImage
                className="cards__item__img"
                alt="Heavy Lifting Moving"
                src="../images/heavy-lifting.jpg"
                placeholder="blurred"
                loading="eager"
                layout="constrained"
                fadeIn={false}
              />
            </CardItem>
          </ul>
          <ul className="cards__items">
            <CardItem
              text="Furniture Delivery Service"
              label="Furniture Delivery"
              path="/FurnitureDelivery"
            >
              <StaticImage
                className="cards__item__img"
                alt="Furniture Delivery Service"
                src="../images/elevator.jpg"
                placeholder="blurred"
                loading="eager"
                layout="constrained"
                fadeIn={false}
              />
            </CardItem>
            <CardItem
              text="Kansas City Junk Removal"
              label="Junk Removal"
              path="/JunkRemoval"
            >
              <StaticImage
                className="cards__item__img"
                alt="Kansas City Junk Removal"
                src="../images/junk-removal.jpg"
                placeholder="blurred"
                loading="eager"
                layout="constrained"
                fadeIn={false}
              />
            </CardItem>
            <CardItem
              text="Kansas City Packing Service"
              label="Packing Service"
              path="/PackingService"
            >
              <StaticImage
                className="cards__item__img"
                alt="Kansas City Packing Service"
                src="../images/packing.jpg"
                placeholder="blurred"
                loading="eager"
                layout="constrained"
                fadeIn={false}
              />
            </CardItem>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ServiceCards;
